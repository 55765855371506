/* You can add global styles to this file, and also import other style files */

@import '~ng-wizard/themes/ng_wizard_theme_arrows.min.css';
@import '~ng-wizard/themes/ng_wizard_theme_circles.min.css';
@import '~ng-wizard/themes/ng_wizard_theme_dots.min.css';
@import '~ngx-toastr/toastr';
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~primeicons/primeicons.css";
@import "~primeng/resources/themes/saga-blue/theme.css";
@import "~primeng/resources/primeng.min.css";

@font-face {
    font-family: 'RenaultFont';
    font-display: auto;
    src: url('./assets/font/icofont/fonts/lat-cyr-regular.woff') format('truetype');
}

body,
html {
    height: 100%;
    width: 100%;
    font-family: 'RenaultFont' !important;
}


.btn-common {
    box-shadow: none !important;
    text-transform: unset !important;
    line-height: 18px !important;
    padding: 10px !important;
}

button:disabled {
    cursor: not-allowed !important;
    opacity: 0.4 !important;
}

.custom-select:focus {
    box-shadow: inset 0 -2px 0 #2196F3 !important;
}


/* You can add global styles to this file, and also import other style files */


/* You can add global styles to this file, and also import other style files */


/* You can add global styles to this file, and also import other style files */

.clearfix {
    clear: both;
}

.pointer {
    cursor: pointer;
}

.ucfirst {
    text-transform: capitalize;
}

.min300 {
    min-height: 300px
}

.app-box {
    padding: 10px;
    margin-bottom: 20px;
    height: 230px;
    background: #b5e8d4;
    overflow: hidden;
    box-shadow: 1px 1px 4px #ccc;
}

.app-box:hover {
    box-shadow: 1px 1px 4px #999;
}

.app-box .remove {
    position: absolute;
    right: 21px;
    top: 0px;
    color: #545454;
    display: none;
}

.app-box:hover .remove {
    display: block;
}

.inline-btn {
    background: #00b970;
    padding: 1px 5px;
    color: white;
    display: inline-block;
    margin-bottom: 5px;
}

.box {
    padding: 10px;
    margin-bottom: 10px;
    overflow: auto;
    box-shadow: 1px 1px 4px #ddd;
}

.form-group .form-control {
    font-size: inherit;
    background-color: inherit;
}

.dndDropArea {
    padding: 10px;
    border: dotted #ddd 2px;
}

.dndDropArea.empty {
    min-height: 200px;
    border: dotted #ddd 2px;
}

.field {
    padding: 5px;
    margin: 15px;
    margin-bottom: 10px;
    position: relative;
    border: 1px solid #E6E6EC;
}

.field:hover {
    box-shadow: 1px 1px 4px #ddd;
}

.toggle-Wrapper {
    padding: 15px;
    background: #d6d6d629;
}

.dndHandle {
    cursor: pointer;
    color: #999;
}

.dndDraggingSource {
    opacity: 0.3;
}

.dndPlaceholder {
    background: rgb(206, 238, 238);
    border-radius: 2px;
    padding: 20px;
    margin-bottom: 10px;
}

.field .fa {
    /* width: 30px;
    height: 30px; */
    text-align: center;
    font-size: 20px;
    vertical-align: middle;
}

.red {
    color: #d9534f
}

.fa-trash {
    color: #d9534f
}

.marginT60 {
    margin-top: 60px;
}

.color-red {
    color: red;
}

.report-block {
    padding: 10px;
    background: #f8f8f8;
    margin-bottom: 20px;
}

.report-block .row:nth-child(even) {
    background: #fff;
}

.ftheme {
    width: 100%;
}


/* 
.dndDropArea .field .fa {
    opacity: 0;
} */


/* .dndDropArea .field:hover .fa {
    opacity: 1;
} */

.colorpick {
    height: 25px;
    background: white;
    border: none;
}

:host ::ng-deep .ngx-pagination li:not(.ellipsis) {
    display: inline-block;
    margin: 3px;
    border: 1px solid #777f91;
    width: 35px;
    height: 35px;
    padding: 8px 0px;
    border-radius: 20px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;

}

:host ::ng-deep .ngx-pagination a {

    padding: 0 !important;

}

:host ::ng-deep .ngx-pagination li.small-screen {
    display: none !important;
}

:host ::ng-deep .ngx-pagination .pagination-previous,
:host ::ng-deep .ngx-pagination .pagination-next {
    display: inline-block;
    border-radius: 50%;
    margin: 0 !important;
    border: none !important;
    color: #ffffff;
    background: none !important;
}

:host ::ng-deep .ngx-pagination .pagination-previous a {
    margin: 0 !important;
    position: relative;
}

:host ::ng-deep .ngx-pagination a:hover {
    background: transparent !important
}

:host ::ng-deep .ngx-pagination .pagination-previous a,
:host ::ng-deep .ngx-pagination .pagination-next a {
    padding: 0 !important;
    min-width: 25px !important;
    position: relative;
}

:host ::ng-deep .ngx-pagination .pagination-next a::after,
:host ::ng-deep .ngx-pagination .pagination-next.disabled::after {
    display: inline-block;
    content: "❯";
    color: #1f2532;
    border-radius: 50%;
    border: 1px solid #ffce33;
    background: #ffce33;
    padding: 3px;
    width: 35px;
    height: 35px;
    font-size: 12px;
    position: absolute;
    line-height: 1.42857;
    font-size: 20px;
    left: 0;
    top: 5px;
    padding: 2px !important;
}

:host ::ng-deep .ngx-pagination .pagination-previous a::before,
:host ::ng-deep .ngx-pagination .pagination-previous.disabled::before {
    display: inline-block;
    content: "❮";
    color: #1f2532;
    border: 1px solid #ffce33;
    padding: 7px px !important;
    width: 35px;
    height: 35px;
    background: #ffce33;
    text-align: center;
    border-radius: 20px;
    margin-right: 0 !important;
    position: absolute;
    left: -5px;
    top: 6px;
    font-size: 20px;
    padding: 2px !important;
}

:host ::ng-deep .ngx-pagination li:not(.current):hover {
    display: inline-block;
    border-radius: 50%;
    border: solid 1px #ffd658 !important;
    color: #232628 !important;
    background: #ffffff !important;
    min-width: 25px !important;
    font-weight: 500;
}

:host ::ng-deep .ngx-pagination li.pagination-next:hover,
:host ::ng-deep .ngx-pagination li.pagination-next.disabled:hover,
:host ::ng-deep .ngx-pagination li.pagination-previous:hover,
:host ::ng-deep .ngx-pagination li.pagination-previous.disabled:hover {
    display: inline-block;
    border-radius: 0 !important;
    margin: 0 !important;
    border: none !important;
    color: none !important;
    background: transparent !important;

}

:host ::ng-deep .ngx-pagination .current {
    display: inline-block;
    border-radius: 50%;
    color: #ffffff;
    margin: 3px;
    background: #777f91 !important;
    border: 1px solid #777f91 !important;
}

:host ::ng-deep .ngx-pagination a {
    border-radius: 50% !important;
    color: #000;
    min-width: 25px !important;
}

:host ::ng-deep .ngx-pagination a:after {
    border-radius: 50% !important;
    color: #000;
    min-width: 25px !important;
}


:host ::ng-deep .ngx-pagination .current a {
    min-width: 25px !important;
    padding: 0 !important;
}

:host ::ng-deep .ngx-pagination li.ellipsis:hover {
    display: inline-block;
    border-radius: 0 !important;
    border: none !important;
    color: none !important;
    background: none !important;
    min-width: 10px !important;
}

.ngx-pagination .ellipsis span:nth-child(2) {
    font-weight: bold;
}

:host ::ng-deep .ngx-pagination li.ellipsis a {
    margin: 3px !important;
    padding: 0 !important;
    min-width: 10px !important;
}

:host ::ng-deep .ngx-pagination .disabled {
    display: none !important;
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: default;
}


:host ::ng-deep .ngx-pagination .current:hover {
    display: inline-block;
    border-radius: 50%;
    color: #ffffff;
    margin: 3px;
    padding: 8px 0px;
    background: #777f91 !important;
    border: 1px solid #777f91 !important;
}

.table-wrapper {
    /* width: 700px;
    margin: 30px auto;
    background: #fff;
    padding: 20px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05); */
    background: #fff;
    padding: 20px;
    /* box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2),
        0 8px 10px 1px rgba(0, 0, 0, .14),
        0 3px 14px 2px rgba(0, 0, 0, .12); */
    /*box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;*/
    overflow-y: hidden !important;
}

.table-title {
    padding-bottom: 10px;
    margin: 0 0 10px;
}

.table-title h2 {
    margin: 6px 0 0;
    font-size: 22px;
}


td,
thead {
    text-align: center;
}

.table-title .add-new,
.save-btn {
    float: right;
    /* height: 30px; */
    /* font-weight: bold; */
    font-size: 1rem;
    text-shadow: none;
    min-width: 100px;
    border-radius: 0.35rem;
    line-height: 13px;
    background: #efdf00 !important;
    border-color: #efdf00 !important;
    color: #000 !important;
    font-weight: 700 !important;
}

.add-new {
    float: right;
    /* height: 30px; */
    /* font-weight: bold; */
    font-size: 1rem;
    text-shadow: none;
    min-width: 100px;
    border-radius: 0.35rem;
    line-height: 13px;
    background: #efdf00 !important;
    border-color: #efdf00 !important;
    color: #000 !important;
    font-weight: 700 !important;
}

.table-title .add-new:hover,
.table-title .add-new:focus {
    color: #fff !important;
    background-color: #000 !important;
    border: 1px solid #000 !important;
}

.add-new:hover,
.add-new:focus {
    color: #fff !important;
    background-color: #000 !important;
    border: 1px solid #000 !important;
}

.table-title .add-new i {
    margin-right: 4px;
}


/* table.table {
    table-layout: fixed;
} */

table.table tr th,
table.table tr td {
    border-color: #e9e9e9;
}

table.table th i {
    font-size: 13px;
    margin: 0 5px;
    cursor: pointer;
}

table.table th:last-child {
    width: 100px;
}

table.table td a {
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    min-width: 24px;
}

table.table td a:hover {
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
}

.nav-tabs .nav-link:focus:hover {
    box-shadow: none !important;
}

.nav-tabs .nav-link,
.nav-tabs .nav-link:focus {
    box-shadow: none !important;
}

.nav-tabs .nav-link,
.nav-tabs .nav-link:focus {
    border-radius: 0 !important;
}

.nav-link.active {
    background-color: #fc3;
    color: #000 !important;
    box-shadow: none !important;
}

.btn-renault-primary {
    color: #000 !important;
    background: #e6e6e6 !important;
    border-color: #e6e6e6 !important;
    box-shadow: none !important;
    font-size: 14px !important;
    font-weight: 500;
    /* text-transform: unset !important; */

}

.btn-secondary {
    background: #efdf00 !important;
    color: #000 !important;
    border-color: #efdf00 !important;
    box-shadow: none !important;
    font-size: 14px !important;
    font-weight: 500;
    /* text-transform: unset !important; */
}

.nav-link:hover {
    cursor: pointer;
}

table.table td a.add {
    color: #27C46B;
}

table.table td a.edit {
    color: #FFC107;
}

table.table td a.delete {
    color: rgb(36, 189, 227);
}

table.table td i {
    font-size: 17px;
}

table.table td a.add i {
    font-size: 24px;
    margin-right: -1px;
    position: relative;
    top: 3px;
}

table.table .form-control {
    height: 32px;
    line-height: 32px;
    box-shadow: none;
    border-radius: 2px;
}

table.table .form-control.error {
    border-color: #f50000;
}

table.table td .add {
    display: none;
}

td,
th {
    word-wrap: break-word;
    max-width: 300px;
    color: #1f2532;
    padding: 0.5rem !important;
    text-align: center;
    vertical-align: middle;
}

table th:nth-child(1),
table tr td:nth-child(1) {
    text-align: left;

}

label {
    color: #000 !important;
}

#events-table tr:nth-child(odd) td {
    background-color: #f3f3f9 !important;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f8f9fc !important;
}


#events-table tr:nth-child(odd) td {
    background-color: #f3f3f9 !important;
}

:host ::ng-deep .ngx-pagination li:not(.ellipsis) {
    display: inline-block;
    margin: 3px;
    border: 1px solid #777f91;
    width: 35px;
    height: 35px;
    padding: 8px 0px;
    border-radius: 20px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;

}

:host ::ng-deep .ngx-pagination a {

    padding: 0 !important;

}

:host ::ng-deep .ngx-pagination li.small-screen {
    display: none !important;
}

:host ::ng-deep .ngx-pagination .pagination-previous,
:host ::ng-deep .ngx-pagination .pagination-next {
    display: inline-block;
    border-radius: 50%;
    margin: 0 !important;
    border: none !important;
    color: #ffffff;
    background: none !important;
}

:host ::ng-deep .ngx-pagination .pagination-previous a {
    margin: 0 !important;
    position: relative;
}

:host ::ng-deep .ngx-pagination a:hover {
    background: transparent !important
}

:host ::ng-deep .ngx-pagination .pagination-previous a,
:host ::ng-deep .ngx-pagination .pagination-next a {
    padding: 0 !important;
    min-width: 25px !important;
    position: relative;
}

:host ::ng-deep .ngx-pagination .pagination-next a::after,
:host ::ng-deep .ngx-pagination .pagination-next.disabled::after {
    display: inline-block;
    content: "❯";
    color: #1f2532;
    border-radius: 50%;
    border: 1px solid #ffce33;
    background: #ffce33;
    padding: 3px;
    width: 35px;
    height: 35px;
    font-size: 12px;
    position: absolute;
    line-height: 1.42857;
    font-size: 20px;
    left: 0;
    top: -8px;
    padding: 2px !important;
}

:host ::ng-deep .ngx-pagination .pagination-previous a::before,
:host ::ng-deep .ngx-pagination .pagination-previous.disabled::before {
    display: inline-block;
    content: "❮";
    color: #1f2532;
    border: 1px solid #ffce33;
    padding: 7px px !important;
    width: 35px;
    height: 35px;
    background: #ffce33;
    text-align: center;
    border-radius: 20px;
    margin-right: 0 !important;
    position: absolute;
    left: -5px;
    top: -7px;
    font-size: 20px;
    padding: 2px !important;
}

:host ::ng-deep .ngx-pagination li:not(.current):hover {
    display: inline-block;
    border-radius: 50%;
    border: solid 1px #ffd658 !important;
    color: #232628 !important;
    background: #ffffff !important;
    min-width: 25px !important;
    font-weight: 500;
}

:host ::ng-deep .ngx-pagination li.pagination-next:hover,
:host ::ng-deep .ngx-pagination li.pagination-next.disabled:hover,
:host ::ng-deep .ngx-pagination li.pagination-previous:hover,
:host ::ng-deep .ngx-pagination li.pagination-previous.disabled:hover {
    display: inline-block;
    border-radius: 0 !important;
    margin: 0 !important;
    border: none !important;
    color: none !important;
    background: transparent !important;

}

:host ::ng-deep .ngx-pagination .current {
    display: inline-block;
    border-radius: 50%;
    color: #ffffff;
    margin: 3px;
    background: #777f91 !important;
    border: 1px solid #777f91 !important;
}

:host ::ng-deep .ngx-pagination a {
    border-radius: 50% !important;
    color: #000;
    min-width: 25px !important;
}

:host ::ng-deep .ngx-pagination a:after {
    border-radius: 50% !important;
    color: #000;
    min-width: 25px !important;
}


:host ::ng-deep .ngx-pagination .current a {
    min-width: 25px !important;
    padding: 0 !important;
}

:host ::ng-deep .ngx-pagination li.ellipsis:hover {
    display: inline-block;
    border-radius: 0 !important;
    border: none !important;
    color: none !important;
    background: none !important;
    min-width: 10px !important;
}

.ngx-pagination .ellipsis span:nth-child(2) {
    font-weight: bold;
}

:host ::ng-deep .ngx-pagination li.ellipsis a {
    margin: 3px !important;
    padding: 0 !important;
    min-width: 10px !important;
}

:host ::ng-deep .ngx-pagination .disabled {
    display: none !important;
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: default;
}


:host ::ng-deep .ngx-pagination .current:hover {
    display: inline-block;
    border-radius: 50%;
    color: #ffffff;
    margin: 3px;
    padding: 8px 0px;
    background: #777f91 !important;
    border: 1px solid #777f91 !important;
}


.nav-tabs {
    border-bottom: none !important;
}